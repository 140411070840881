import logo from "./logo.svg";
import "./App.css";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

function App() {
  const { isLoading, error, data } = useVisitorData();
  const showFingerprint = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (error) {
      return <div>An error occurred: {error.message}</div>;
    }

    if (data) {
      console.log(data);
      // perform some logic based on the visitor data
      return (
        <div style={{ color: "black" }}>
          <div style={{ marginBottom: "20px" }}>
            Welcome {data.visitorFound ? `back ${data.visitorId}` : ""}!
          </div>
          <div>{JSON.stringify(data)}</div>
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {showFingerprint()}
      </header>
    </div>
  );
}

export default App;
